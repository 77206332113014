<template>
  <div class="d-flex flex-column">
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`" zIndex="99999"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>
    <v-snackbar
      v-model="isShowOverallMessages"
      color="grey lighten-1"
      elevation="24"
      :timeout="-1"
      centered
    >
      <template v-for="(item, index) in overall_messages">
        <div class="globalsnackbarparagraph" :key="index">
          <div v-html="item.messageContents" class="text--primary"></div>
          <v-divider></v-divider>
        </div>
      </template>
      <template v-slot:action="{ attrs }">
        <v-btn
          class="text--primary"
          elevation="2"
          outlined
          v-bind="attrs"
          @click="markedAsReadOverallMessages"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>
    <div
      class="d-flex justify-content-between align-center mb-2"
      v-if="!this.isLoadingSessionpospagedata"
    >
      <b-button
        class="mr-1 btn_back_surveyanswerpage"
        variant="outline-secondary"
        size="sm"
        @click="onClickBackButton"
      >
        <b-icon icon="arrow-left"></b-icon> volver
      </b-button>
      <div class="d-flex justify-content-center align-center">
        <b-button
          class="mx-1"
          variant="outline-secondary"
          size="sm"
          @click="showDialogOnePage()"
        >
          One Page
        </b-button>
        <b-button
          class="mx-1"
          variant="outline-secondary"
          size="sm"
          @click="showDialogPromos()"
        >
          Promos
        </b-button>
        <b-button
          class="mx-1"
          variant="outline-secondary"
          size="sm"
          @click="onClickPOSData()"
        >
          <template v-if="!posSessionGeoLocation">
            <v-badge color="pink" right dot class="right_center_badge mr-1">
              <span class="pr-2">Datos PDV</span>
            </v-badge>
          </template>
          <template v-else>Datos PDV</template>
        </b-button>
      </div>
      <b-button
        variant="primary"
        size="sm"
        style="color: #fff;"
        @click="showAddClientsDialog()"
        v-if="!isEndedSession"
      >
        <b-icon icon="plus-circle"></b-icon>
        Añadir Visita
      </b-button>
      <div v-else>&nbsp;</div>
    </div>
    <b-alert v-else show variant="info">
      <b-icon icon="arrow-counterclockwise" animation="spin-reverse"></b-icon>
      cargando datos...
    </b-alert>
    <div class="border mt-2 mb-2">
      <template v-if="posSession">
        <v-row class="py-3 px-3">
          <v-col cols="12" sm="12" md="4">
            <div class="d-flex justify-content-start align-center flex-wrap">
              <template v-for="(brandItem, bKey) in worksessionPosBrands">
                <b-button
                  :key="`brandnavbtn_${bKey}`"
                  variant="outline-secondary"
                  class="mr-2 mb-2"
                  v-if="selectedBrandId != brandItem.id"
                  @click="onClickSelectedBrand(brandItem.id)"
                >
                  <v-badge color="pink" inline dot>
                    {{ brandItem.name }}
                  </v-badge>
                </b-button>
                <b-button
                  :key="`brandnavbtn_${bKey}`"
                  variant="outline-secondary"
                  class="mr-2 mb-2"
                  @click="onClickSelectedBrand(brandItem.id)"
                  v-else
                >
                  <v-badge color="green" inline dot>
                    {{ brandItem.name }}
                  </v-badge>
                </b-button>
              </template>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <p class="trencadis-title my-3 text-center">
              [{{ posSession.po.code || posSession.po.id }}]
              <template v-if="routePosItemIdLabel(posSession)">
                [{{ routePosItemIdLabel(posSession) }}]
              </template>
              {{ posSession.po.name }}
            </p>
            <div class="d-flex justify-content-center align-center">
              <sessionTagLabels :postaglabels="posTagLabels"></sessionTagLabels>
            </div>
          </v-col>
          <v-col
            v-if="posContactsOfSelectedBrand.length > 0"
            cols="12"
            sm="12"
            md="4"
          >
            <div class="d-flex justify-content-center align-center flex-wrap">
              <template
                v-for="(contactItem, contactKey) in posContactsOfSelectedBrand"
              >
                <span :key="`contacts_${contactKey}`" class="mx-3 my-2">
                  {{ contactItem.contact }}
                  <v-icon medium color="primary" class="mx-1">
                    mdi-phone
                  </v-icon>
                  <a class="btn_call_link" :href="`tel:${contactItem.phone}`">{{
                    contactItem.phone
                  }}</a>
                </span>
              </template>
            </div>
          </v-col>
        </v-row>
      </template>
    </div>

    <div
      class="d-flex flex-column mt-2"
      v-if="posSession && !this.isLoadingSessionpospagedata"
    >
      <v-row v-if="!isEmptyBrandSalesStatistics">
        <v-col
          cols="12"
          md="2"
          class="d-flex align-center justify-content-center"
        >
          <v-autocomplete
            v-if="salesFamilyList"
            v-model="selectedSalesFamilies"
            v-bind:label="$t('brands.family')"
            :items="salesFamilyList"
            filled
            hide-no-data
            hide-details
            multiple
            clearable
            @change="onChangeSalesFamily"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-row>
            <v-col cols="12" sm="12" md="12" class="text-center"
              >vs Last Year</v-col
            >
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              class="d-flex align-start justify-content-center text-center brand_sales_module"
            >
              <template>
                <sessionPosStatistics
                  :label="`Vta Año (€)`"
                  :value="brand_sales_statistics_data?.sales_ytd"
                  :valueType="`SALES`"
                  :displayAmount="
                    brand_sales_statistics_data?.displayAmounts?.sales_ytd
                  "
                />
              </template>
              <template>
                <sessionPosStatistics
                  :label="`Vta Ult. 12M (€)`"
                  :value="brand_sales_statistics_data?.tam"
                  :valueType="`SALES`"
                  :displayAmount="
                    brand_sales_statistics_data?.displayAmounts?.sales_tam
                  "
                />
              </template>
              <template>
                <sessionPosStatistics
                  :label="`Mes (€)`"
                  :value="brand_sales_statistics_data?.sales_month"
                  :valueType="`SALES`"
                  :displayAmount="
                    brand_sales_statistics_data?.displayAmounts?.sales_month
                  "
                />
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              class="d-flex align-start justify-content-center text-center brand_sales_module"
            >
              <template>
                <sessionPosStatistics
                  :label="`Vta Año (Vol)`"
                  :value="brand_sales_statistics_data?.vol_sales_ytd"
                  :valueType="`VOLUMN`"
                  :displayAmount="
                    brand_sales_statistics_data?.displayAmounts?.vol_ytd
                  "
                />
              </template>
              <template>
                <sessionPosStatistics
                  :label="`Vta Ult. 12M (Vol)`"
                  :value="brand_sales_statistics_data?.vol_tam"
                  :valueType="`VOLUMN`"
                  :displayAmount="
                    brand_sales_statistics_data?.displayAmounts?.vol_tam
                  "
                />
              </template>
              <template>
                <sessionPosStatistics
                  :label="`Mes (Vol)`"
                  :value="brand_sales_statistics_data?.vol_sales_month"
                  :valueType="`VOLUMN`"
                  :displayAmount="
                    brand_sales_statistics_data?.displayAmounts?.vol_month
                  "
                />
              </template>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-row>
            <v-col cols="12" sm="12" md="12" class="text-center">vs Plan</v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              class="d-flex align-start justify-content-center text-center brand_sales_module"
            >
              <template>
                <sessionPosStatistics
                  :label="`Vta Año (€)`"
                  :value="brand_sales_statistics_data?.vs_plan_sales_ytd"
                />
              </template>
              <template>
                <sessionPosStatistics
                  :label="`Vta Ult. 12M (€)`"
                  :value="brand_sales_statistics_data?.vs_plan_tam"
                />
              </template>
              <template>
                <sessionPosStatistics
                  :label="`Mes (€)`"
                  :value="brand_sales_statistics_data?.vs_plan_sales_month"
                />
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              class="d-flex align-start justify-content-center text-center brand_sales_module"
            >
              <template>
                <sessionPosStatistics
                  :label="`Vta Año (Vol)`"
                  :value="brand_sales_statistics_data?.vs_plan_vol_sales_ytd"
                />
              </template>
              <template>
                <sessionPosStatistics
                  :label="`Vta Ult. 12M (Vol)`"
                  :value="brand_sales_statistics_data?.vs_plan_vol_tam"
                />
              </template>
              <template>
                <sessionPosStatistics
                  :label="`Mes (Vol)`"
                  :value="brand_sales_statistics_data?.vs_plan_vol_sales_month"
                />
              </template>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="d-flex align-top justify-content-center"
          v-if="brand_sales_statistics_data?.last_updated_date"
        >
          <p class="last_updated_caption">
            Last Update
            <span class="bold">
              {{ brand_sales_statistics_data.last_updated_date }}
            </span>
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-card elevation="5" outlined>
            <v-card-title>
              <v-row>
                <v-col cols="12" md="8">
                  Apuntes Ult. Visita
                </v-col>
                <v-col cols="12" md="4" class="text-right">
                  <v-btn
                    color="success"
                    dark
                    small
                    class="mx-1"
                    @click="onClickAddMessage(`BRAND_MESSAGING`)"
                    v-if="!isEndedSession"
                  >
                    <b-icon icon="plus"></b-icon>
                    {{ $t("Add") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-card-text>
              <div class="text--primary">
                <template
                  v-if="
                    brand_incidence_messages &&
                      brand_incidence_messages?.brand_messaging.length > 0
                  "
                >
                  <MessageListItem
                    v-for="(messageItem,
                    messageKey) in brand_incidence_messages.brand_messaging"
                    :key="`brand_message_list_item_${messageKey}`"
                    :messageItem="messageItem"
                    :messageCategory="`BRAND_MESSAGING`"
                    @onClickMessageItem="
                      (messageCategory, messageItem) =>
                        onClickMessageItem(messageCategory, messageItem)
                    "
                  />
                </template>
                <b-alert
                  v-else-if="isLoadingSessionpospagedata"
                  show
                  variant="info"
                >
                  <b-icon
                    icon="arrow-counterclockwise"
                    animation="spin-reverse"
                  ></b-icon>
                  cargando datos...
                </b-alert>
                <h6 v-else>
                  No hay datos para mostrar...
                </h6>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-card elevation="5" outlined>
            <v-card-title>
              <v-row>
                <v-col cols="12" md="8">{{ $t("Incidence") }}</v-col>
                <v-col cols="12" md="4" class="text-right">
                  <v-btn
                    color="success"
                    dark
                    small
                    class="mx-1"
                    @click="onClickAddMessage(`INCIDENCE_MESSAGING`)"
                    v-if="!isEndedSession"
                  >
                    <b-icon icon="plus"></b-icon>
                    {{ $t("Add") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-card-text>
              <div class="text--primary">
                <template
                  v-if="
                    brand_incidence_messages &&
                      brand_incidence_messages?.incidence_messaging.length > 0
                  "
                >
                  <MessageListItem
                    v-for="(messageItem,
                    messageKey) in brand_incidence_messages.incidence_messaging"
                    :key="`incidence_message_list_item_${messageKey}`"
                    :mStatus="mStatus"
                    :messageItem="messageItem"
                    :messageCategory="`INCIDENCE_MESSAGING`"
                    @onClickMessageItem="
                      (messageCategory, messageItem) =>
                        onClickMessageItem(messageCategory, messageItem)
                    "
                  />
                </template>
                <b-alert
                  v-else-if="isLoadingSessionpospagedata"
                  show
                  variant="info"
                >
                  <b-icon
                    icon="arrow-counterclockwise"
                    animation="spin-reverse"
                  ></b-icon>
                  cargando datos...
                </b-alert>
                <h6 v-else>
                  No hay datos para mostrar...
                </h6>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <DialogAddMessage
          ref="dialogAddMessage"
          @onClickSave="
            (messageCategory, formData) =>
              saveMessage(messageCategory, formData)
          "
        />

        <DialogMessageList
          ref="dialogMessageList"
          :mStatus="mStatus"
          @reloadMessages="() => reloadMessages()"
        />
      </v-row>

      <v-row
        class="text--primary"
        v-if="
          currentPromosForSelectedBrand &&
            currentPromosForSelectedBrand.length > 0
        "
      >
        <v-col cols="12" sm="12" md="12">
          <v-card elevation="5" outlined>
            <v-card-title>Promos</v-card-title>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-card-text>
              <promosTable
                :currentPromosForSelectedBrand="currentPromosForSelectedBrand"
                :promosTypeList="promosTypeList"
                :placeTypeList="placeTypeList"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        class="text--primary"
        v-if="promo_locals && promo_locals.length > 0"
      >
        <v-col cols="12" sm="12" md="12">
          <v-card elevation="5" outlined>
            <v-card-title>Promo Local</v-card-title>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-card-text>
              <promoLocalTable :promo_locals="promo_locals" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="text--primary" v-if="kpi_questions.length > 0">
        <v-col cols="12" sm="12" md="12">
          <v-card elevation="5" outlined>
            <v-card-title>KPI's</v-card-title>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-card-text>
              <v-row>
                <template v-for="questionId in sortedKeysQuestions">
                  <template
                    v-for="question in [
                      getKPIQuestionItemWithSortedKey(kpi_questions, questionId)
                    ]"
                  >
                    <v-col
                      cols="12"
                      sm="4"
                      md="3"
                      lg="2"
                      :key="`kpi_question_${question.id}`"
                      v-if="question"
                    >
                      <div
                        class="d-flex justify-content-center align-center mb-2 text--primary"
                      >
                        <KPIQuestionItem
                          :ref="`survey_kpi_question_ref_${question.id}`"
                          :question="question"
                          :readOnlyInputs="false"
                          :fromPageCategory="fromPageCategory"
                        />
                      </div>
                    </v-col>
                  </template>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        class="text--primary"
        v-if="
          worksession_pos_daily_status?.compared_visit_results.length > 0 ||
            worksession_pos_daily_status?.pending_visit_results.length > 0
        "
      >
        <v-col cols="12" sm="12" md="12">
          <v-card elevation="5" outlined>
            <v-card-title>Perfect Store</v-card-title>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-card-text>
              <v-row class="status_wrapper">
                <v-col cols="12" sm="3" md="3">
                  <b-card
                    bg-variant="dark"
                    v-bind:header="$t('objective')"
                    class="text-center"
                  >
                    <b-card-text>
                      &nbsp;
                      {{
                        numberFormatEsCustomDecimalCount(
                          worksession_pos_daily_status?.posBrandPSObjective,
                          0
                        )
                      }}
                      &nbsp;
                    </b-card-text>
                  </b-card>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <b-card
                    bg-variant="dark"
                    v-bind:header="current_visit_card_title"
                    class="text-center"
                  >
                    <b-card-text>
                      &nbsp;
                      {{
                        numberFormatEsCustomDecimalCount(
                          worksession_pos_daily_status?.posBrandPSLastVisit,
                          0
                        )
                      }}
                      &nbsp; / &nbsp;
                      {{
                        numberFormatEsCustomDecimalCount(
                          worksession_pos_daily_status?.posBrandPSMaxLastVisit,
                          0
                        )
                      }}
                    </b-card-text>
                  </b-card>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <b-card
                    bg-variant="dark"
                    v-bind:header="last_visit_card_title"
                    class="text-center"
                  >
                    <b-card-text>
                      &nbsp;
                      {{
                        numberFormatEsCustomDecimalCount(
                          worksession_pos_daily_status?.posBrandPSEndPreviousVisit,
                          0
                        )
                      }}
                      &nbsp;
                    </b-card-text>
                  </b-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <div
                    class="d-flex justify-content-center align-center mb-2 text--primary"
                  >
                    <table style="width: 100%" class="ventas-table" border="1">
                      <colgroup>
                        <col width="60%" />
                        <col width="40%" />
                      </colgroup>
                      <thead>
                        <tr class="">
                          <th class="no-border table-title">
                            {{ `${current_visit_card_title} ${$t("Result")}s` }}
                          </th>

                          <th class="no-border">
                            {{
                              `${
                                worksession_pos_daily_status?.totalComparedScore >
                                0
                                  ? "+"
                                  : ""
                              }${this.numberFormatEsCustomDecimalCount(
                                worksession_pos_daily_status?.totalComparedScore,
                                2
                              )} pts`
                            }}
                          </th>
                        </tr>
                        <tr class="black-tr">
                          <th class="ventas-table-th"></th>
                          <th class="ventas-table-th">{{ $t("Points") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template
                          v-if="
                            worksession_pos_daily_status?.compared_visit_results
                              .length > 0
                          "
                        >
                          <tr
                            v-for="(row,
                            rowKey) in worksession_pos_daily_status?.compared_visit_results"
                            :key="`table_row_${rowKey}`"
                            :class="getVisitTableRowStyle(row)"
                          >
                            <td class="ventas-table-row-title">
                              <b>{{ row?.rowLabelV2 }}</b>
                            </td>
                            <td class="ventas-table-row-cell">
                              {{
                                `${row.comparedScore > 0 ? "+" : ""}${
                                  row.comparedScore
                                } pts`
                              }}
                            </td>
                          </tr>
                        </template>
                        <tr v-else>
                          <td colspan="2">
                            <b>Sin cambios vs última visita</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div
                    class="d-flex justify-content-center align-center mb-2 text--primary"
                  >
                    <table style="width: 100%" class="ventas-table" border="1">
                      <colgroup>
                        <col width="65%" />
                        <col width="35%" />
                      </colgroup>
                      <thead>
                        <tr class="">
                          <th class="no-border table-title">
                            {{ $t("Pending") }} {{ $t("Points") }}
                          </th>
                          <th class="no-border">&nbsp;</th>
                        </tr>
                        <tr class="black-tr">
                          <th class="ventas-table-th"></th>
                          <th class="ventas-table-th">Puntuacion</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template
                          v-if="
                            worksession_pos_daily_status?.pending_visit_results
                              .length > 0
                          "
                        >
                          <tr
                            v-for="(row,
                            rowKey) in worksession_pos_daily_status?.pending_visit_results"
                            :key="`pending_table_row_${rowKey}`"
                            class="grey-tr"
                          >
                            <td class="ventas-table-row-title">
                              <b>{{ row?.rowLabelV2 }}</b>
                            </td>
                            <td class="ventas-table-row-cell">
                              {{ `${Math.abs(row.pending_score)} pts` }}
                            </td>
                          </tr>
                        </template>
                        <tr v-else>
                          <td colspan="3">
                            <b>No hay puntos pendientes</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="text--primary" v-if="!isEmptyActionResults">
        <v-col cols="12" sm="12" md="12">
          <v-card elevation="5" outlined>
            <v-card-title>{{ $t("brands.Actions") }}</v-card-title>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <div
                    class="d-flex justify-content-center align-center mb-2 text--primary"
                  >
                    <table style="width: 100%" class="ventas-table" border="1">
                      <colgroup>
                        <col width="50%" />
                        <col width="50%" />
                      </colgroup>
                      <thead>
                        <tr class="">
                          <th class="no-border table-title">
                            {{ current_visit_card_title }}
                          </th>
                          <th class="no-border"></th>
                        </tr>
                        <tr class="black-tr">
                          <th class="ventas-table-th">
                            {{ `${this.$t("Question")} / ${this.$t("Type")}` }}
                          </th>
                          <th class="ventas-table-th">
                            {{ $t("brands.description") }}
                          </th>
                        </tr>
                      </thead>
                      <template v-if="current_visit_action_results.length > 0">
                        <template
                          v-for="(row,
                          rowKey) in worksession_pos_daily_status?.current_visit_action_results"
                        >
                          <template
                            v-for="(actionItem,
                            actionKey) in row?.filteredActionRecords"
                          >
                            <tr :key="`table_row_${rowKey}_${actionKey}`">
                              <td class="ventas-table-row-title">
                                <b
                                  >{{
                                    actionItem.surveyQuestionLabel
                                      ? `${actionItem.surveyQuestionLabel} / `
                                      : ""
                                  }}
                                  {{ `${actionItem.name} / ${row.rowLabelV2}` }}
                                </b>
                              </td>
                              <td class="ventas-table-row-cell">
                                {{ actionItem.comments }}
                              </td>
                            </tr>
                          </template>
                        </template>
                        <template
                          v-for="(actionItem,
                          actionKey) in worksession_pos_daily_status?.current_visit_no_question_components_action_results"
                        >
                          <tr :key="`table_row_added_${actionKey}`">
                            <td class="ventas-table-row-title">
                              <b
                                >{{
                                  actionItem.surveyQuestionLabel
                                    ? `${actionItem.surveyQuestionLabel} / `
                                    : ""
                                }}
                                {{
                                  `${actionItem.name} / ${actionItem.rowLabelV2}`
                                }}
                              </b>
                            </td>
                            <td class="ventas-table-row-cell">
                              {{ actionItem.comments }}
                            </td>
                          </tr>
                        </template>
                      </template>
                      <tr v-else>
                        <td colspan="2">
                          <b>¡Datos vacíos!</b>
                        </td>
                      </tr>
                    </table>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div
                    class="d-flex justify-content-center align-center mb-2 text--primary"
                  >
                    <table style="width: 100%" class="ventas-table" border="1">
                      <colgroup>
                        <col width="50%" />
                        <col width="50%" />
                      </colgroup>
                      <thead>
                        <tr class="">
                          <th class="no-border table-title">
                            {{ last_visit_card_title }}
                          </th>
                          <th class="no-border"></th>
                        </tr>
                        <tr class="black-tr">
                          <th class="ventas-table-th">
                            {{ `${this.$t("Question")} / ${this.$t("Type")}` }}
                          </th>
                          <th class="ventas-table-th">
                            {{ $t("brands.description") }}
                          </th>
                        </tr>
                      </thead>
                      <template v-if="last_visit_action_results.length > 0">
                        <template
                          v-for="(row,
                          rowKey) in worksession_pos_daily_status?.last_visit_action_results"
                        >
                          <template
                            v-for="(actionItem,
                            actionKey) in row?.filteredActionRecords"
                          >
                            <tr :key="`table_row_${rowKey}_${actionKey}`">
                              <td class="ventas-table-row-title">
                                <b
                                  >{{
                                    actionItem.surveyQuestionLabel
                                      ? `${actionItem.surveyQuestionLabel} / `
                                      : ""
                                  }}
                                  {{ `${actionItem.name} / ${row.rowLabelV2}` }}
                                </b>
                              </td>
                              <td class="ventas-table-row-cell">
                                {{ actionItem.comments }}
                              </td>
                            </tr>
                          </template>
                        </template>
                        <template
                          v-for="(actionItem,
                          actionKey) in worksession_pos_daily_status?.last_visit_no_question_components_action_results"
                        >
                          <tr :key="`table_row_added_${actionKey}`">
                            <td class="ventas-table-row-title">
                              <b
                                >{{
                                  actionItem.surveyQuestionLabel
                                    ? `${actionItem.surveyQuestionLabel} / `
                                    : ""
                                }}
                                {{
                                  `${actionItem.name} / ${actionItem.rowLabelV2}`
                                }}
                              </b>
                            </td>
                            <td class="ventas-table-row-cell">
                              {{ actionItem.comments }}
                            </td>
                          </tr>
                        </template>
                      </template>
                      <tr v-else>
                        <td colspan="2">
                          <b>¡Datos vacíos!</b>
                        </td>
                      </tr>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="text-center">
        <v-col cols="12" sm="12" md="12">
          <v-btn
            color="success"
            dark
            class="mx-1 my-2"
            @click="onClickSurveyOrSalesNext"
            :loading="isProcessingGoNext"
            v-if="isProcessingNext"
          >
            {{ $t("Following") }}
            <b-icon icon="arrow-right"></b-icon>
          </v-btn>
          <v-btn
            color="success"
            dark
            class="mx-1 my-2"
            @click="onClickEndVisit"
            :loading="isProcessingEndVisit"
            v-if="!isProcessingNext"
          >
            {{ `${$t("End")} ${$t("Visit")}` }}
            <b-icon icon="arrow-right"></b-icon>
          </v-btn>
        </v-col>
      </v-row>

      <DialogOnePage
        ref="dialogOnePage"
        :routeId="posSession.routeId"
        :posId="posSession.posId"
        :worksessionPosBrands="worksessionPosBrands"
      />

      <DialogPromos
        ref="dialogPromos"
        :routeId="posSession.routeId"
        :posId="posSession.posId"
        :worksessionPosBrands="worksessionPosBrands"
      />
    </div>
    <b-alert v-else show variant="info">
      <b-icon icon="arrow-counterclockwise" animation="spin-reverse"></b-icon>
      cargando datos...
    </b-alert>

    <v-dialog v-model="showDistanceCheckDialog" persistent max-width="700px">
      <v-form ref="form_distance_check" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="text-h6">
            Estás a más de 200mts de distancia. Manten la visita presencial
            añadiendo la foto de la entrada y pulsando estoy en el POS o pulsa
            cambiar tipo visita para pasar a telefonico.
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <gennera-picture-input
                    :crop="false"
                    class="my-picture-input"
                    ref="pictureInput"
                    width="500"
                    height="300"
                    size="5"
                    :prefill="
                      getImageUri(selected_image ? selected_image : null)
                    "
                    accept="image/jpeg,image/png"
                    button-class="btn btn-outline-info"
                    aspect="Landscape"
                    :customStrings="{
                      upload: this.$t('uploading_image'),
                      change: this.$t('change'),
                      tap: 'Tap here to select a photo from your gallery'
                    }"
                    @change="onChangePicture"
                    :capture="`camera`"
                  >
                  </gennera-picture-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              class="white--text"
              :loading="isLoading['uploading']"
              @click.stop="startWithPresentByUploading"
            >
              Estoy en el POS
            </v-btn>
            <v-btn
              color="info"
              class="white--text"
              :loading="isLoading['changingVisitType']"
              @click.stop="changeVisitTypeFromPresentToPhone"
            >
              Cambiar tipo de visita
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click.stop="closeDistanceCheckDialog"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogCheckDistance" persistent max-width="700px">
      <v-card>
        <v-card-title class="text-h6">
          {{ $t("session.confirmation_change_visittype_phone") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click.stop="confirmLocationDistance(true)"
          >
            {{ $t("Yes") }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click.stop="confirmLocationDistance(false)"
          >
            {{ $t("No") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DialogStartKM
      v-model="showStartKMDialog"
      ref="dialogStartKM"
      :vehicleTypeList="vehicleTypeList"
      :session="session"
      :current_km_data="current_km_data"
      :mainRoute="mainRoute"
      @savingStartKM="response => savingStartKM(response)"
    />

    <ConfirmDialogPosPageAddClient
      v-if="posSession"
      ref="confirmDialogPosPageAddClient"
      :visitTypes="visitTypes"
      :routeId="posSession.routeId"
      :posId="posSession.posId"
      :selectableBrands="selectableBrands"
      :confirmSelectionCriteria="confirmSelectionCriteria"
    />
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment";
import DialogOnePage from "./DialogOnePage.vue";
import DialogPromos from "./DialogPromos.vue";
import promosTable from "./promosTable.vue";
import promoLocalTable from "./promoLocalTable.vue";
import { logInfo, logError } from "@/utils";
import haversine from "haversine-distance";
import sessionTagLabels from "@/components/sessionTagLabels";
import sessionPosStatistics from "@/components/sessionPosStatistics";
import MessageListItem from "./messages/MessageListItem";
import DialogAddMessage from "./messages/DialogAddMessage";
import DialogMessageList from "./messages/DialogMessageList";
import ConfirmDialogPosPageAddClient from "./ConfirmDialogPosPageAddClient.vue";
import KPIQuestionItem from "./KPIQuestionItem";
import DialogStartKM from "./DialogStartKM.vue";
import PictureInput from "vue-picture-input";
import GenneraPictureInput from "@/components/GenneraPictureInput.vue";

export default {
  name: "worksessionPos",
  // props: ["brandId"],
  components: {
    DialogOnePage,
    DialogPromos,
    promosTable,
    promoLocalTable,
    sessionTagLabels,
    sessionPosStatistics,
    MessageListItem,
    DialogAddMessage,
    DialogMessageList,
    ConfirmDialogPosPageAddClient,
    DialogStartKM,
    KPIQuestionItem,
    PictureInput,
    GenneraPictureInput
  },
  data: function() {
    return {
      sessionId: null,
      worksessionPosId: null,
      posSession: null,
      session: null,
      mainRoute: null,
      posSessionChainCriteria: null,
      worksessionPosBrands: [],
      posSessionGeoLocation: null,
      dialogCheckDistance: false,
      pdfFilePrefix:
        process.env.VUE_APP_ENV !== "development"
          ? "https://docs.google.com/gview?embedded=true&url="
          : // ? "https://docs.google.com/viewerng/viewer?url="
            "",
      currentPromosForSelectedBrand: [],
      selectedBrandId: null,
      pos_change_gps: null,
      placeTypeList: null,
      promosTypeList: null,
      brand_sales_statistics_data: null,
      salesFamilyList: [],
      selectedSalesFamilies: [],
      worksession_pos_daily_status: null,
      kpi_questions: [],
      sortedKeysQuestions: [],
      shouldCheckFullFilledCols: ["price", "place", "folleto"],

      isShowOverallMessages: false,
      overall_messages: [],
      promo_locals: [],
      brand_incidence_messages: null,
      mStatus: null,

      showStartKMDialog: false,
      vehicleTypeList: [],
      current_km_data: null,
      startedWorksessionPosCount: 0,

      isLoadingSessionpospagedata: false,
      visitTypes: [],
      selectableBrands: [],
      selectedBrandItem: null,
      selectedWorksessionPosBrandItem: null,
      worksessionPosBrandId: null,

      isProcessingEndVisit: false,
      isProcessingGoNext: false,

      /*************************** Distance Check Dialog  *************************/
      showDistanceCheckDialog: false,
      valid: true,
      isLoading: {
        uploading: false,
        changingVisitType: false
      },
      isGpvPosCheckPresent: null,
      isVisitCurrentPos: false,
      intervalId: null,
      selected_image: null,

      /*************************  Overlay *************************/
      isLoadingSpinner: false
    };
  },
  watch: {
    dialogCheckDistance(val) {
      val || this.closeDialogCheckDistance();
    },
    async $route(to, from) {
      console.log("to, from - ", to, from, to === from);
      if (to !== from) {
        await this.onChangeBrandId(to.params.brandId);
      }
    }
  },
  computed: {
    ...mapGetters("auth", [
      "user",
      "isAdmin",
      "isSubAdmin",
      "isBrand",
      "isGPV",
      "isSPV"
    ]),
    ...mapGetters("myroute", [
      "messageVisitAlert",
      "currentPosition",
      // "currentGmapAddress",
      "isEndedSession"
    ]),
    userLiquidationType() {
      let liquidationType = "TOTAL_KM";
      if (this.user && this.user?.liquidationType) {
        liquidationType = this.user.liquidationType;
      }
      return liquidationType;
    },
    posTagLabels() {
      let ret = [];
      if (
        this.posSession &&
        this.posSession?.po &&
        this.posSession.po.postaglabels.length > 0 &&
        this.selectedBrandId
      ) {
        ret = this.posSession.po.postaglabels.filter(
          el => el.brandId == this.selectedBrandId
        );
      }
      return ret;
    },
    isEmptyActionResults() {
      let ret = true;
      if (
        this.current_visit_action_results.length > 0 ||
        this.last_visit_action_results.length > 0
      )
        ret = false;
      return ret;
    },
    isShowOnepageModal() {
      let pdfCount = 0;
      this.onepagePdfList.map(
        el =>
          (pdfCount += el.onepagePdfContents ? el.onepagePdfContents.length : 0)
      );
      return pdfCount > 0;
    },
    isShowPromosModal() {
      let pdfCount = 0;
      this.promosPdfList.map(
        el =>
          (pdfCount += el.promosPdfContents ? el.promosPdfContents.length : 0)
      );
      return pdfCount > 0;
    },
    onepagePdfList() {
      let data = [];
      data = this.worksessionPosBrands.map(el => {
        let push_item = { ...el };
        push_item["onepagePdfContents"] = [];
        push_item["isIncluded"] = true;
        if (push_item?.brand_onepages) {
          push_item["onepagePdfContents"] = push_item.brand_onepages.map(
            oneEl => {
              let onepageItem = {
                ...oneEl
              };
              if (oneEl?.onepagePdfFile?.file) {
                onepageItem["pdfPath"] =
                  this.pdfFilePrefix +
                  ApiService.getFileUri(oneEl.onepagePdfFile);
              }
              return onepageItem;
            }
          );
        }
        return push_item;
      });
      return data;
    },
    promosPdfList() {
      let data = [];
      data = this.worksessionPosBrands.map(el => {
        let push_item = { ...el };
        push_item["promosPdfContents"] = [];
        push_item["isIncluded"] = true;
        if (push_item?.brand_promos) {
          push_item["promosPdfContents"] = push_item.brand_promos.map(
            promosEl => {
              let promosItem = {
                ...promosEl
              };
              if (promosEl?.promosPdfFile?.file) {
                promosItem["pdfPath"] =
                  this.pdfFilePrefix +
                  ApiService.getFileUri(promosEl.promosPdfFile);
              }
              return promosItem;
            }
          );
        }
        return push_item;
      });
      return data;
    },
    posContactsOfSelectedBrand() {
      if (this.selectedBrandId && this.posSession) {
        let contacts = [];
        let posContacts = this.posSession.po?.pos_contacts;
        if (posContacts) {
          let filteredContacts = posContacts.filter(el =>
            el.brands.map(brandEl => brandEl.id).includes(this.selectedBrandId)
          );
          return filteredContacts;
        }
      }
      return [];
    },
    current_visit_card_title() {
      let title = this.$t("lastVisit");
      if (
        this.worksession_pos_daily_status &&
        this.worksession_pos_daily_status?.isFullFillledOfCurrentVisit
      ) {
        title = `${this.$t("Visit")} ${this.$t("Current")}`;
      }
      return title;
    },
    last_visit_card_title() {
      let title = this.$t("End Previous Visit");
      if (
        this.worksession_pos_daily_status &&
        this.worksession_pos_daily_status?.isFullFillledOfCurrentVisit
      ) {
        title = `${this.$t("lastVisit")}`;
      }
      return title;
    },
    isNoSalesAndNoSurveys() {
      let isGoSalesPage = this.checkGoSales();
      let isHasSurveys = this.checkHasSurveys();
      return !isGoSalesPage && !isHasSurveys;
    },
    fromPageCategory() {
      const { frompage } = this.$route.params;
      return frompage;
    },
    isProcessingNext() {
      if (this.fromPageCategory === "route") {
        if (this.isNoSalesAndNoSurveys) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
    distanceBetweenGPSandPOS() {
      let haversine_m = null;
      if (this.posSessionGeoLocation && this.currentPosition) {
        let posLocationPt = {
          lat: this.posSessionGeoLocation.latitude,
          lng: this.posSessionGeoLocation.longitude
        };
        let currentLocationPt = {
          lat: this.currentPosition.latitude,
          lng: this.currentPosition.longitude
        };
        haversine_m = haversine(posLocationPt, currentLocationPt); //Results in meters (default)
      }
      return haversine_m;
      // return 0;
    },
    current_visit_action_results() {
      let action_result = [];
      if (
        this.worksession_pos_daily_status?.current_visit_action_results.length >
        0
      ) {
        this.worksession_pos_daily_status.current_visit_action_results.map(
          el => {
            if (el?.filteredActionRecords.length > 0) {
              el.filteredActionRecords.map(subEl => {
                action_result.push(subEl);
              });
            }
            return el;
          }
        );
      }
      if (
        this.worksession_pos_daily_status
          ?.current_visit_no_question_components_action_results.length > 0
      ) {
        this.worksession_pos_daily_status.current_visit_no_question_components_action_results.map(
          el => {
            action_result.push(el);
            return el;
          }
        );
      }
      return action_result;
    },
    last_visit_action_results() {
      let action_result = [];
      if (
        this.worksession_pos_daily_status?.last_visit_action_results.length > 0
      ) {
        this.worksession_pos_daily_status.last_visit_action_results.map(el => {
          if (el?.filteredActionRecords.length > 0) {
            el.filteredActionRecords.map(subEl => {
              action_result.push(subEl);
            });
          }
          return el;
        });
      }
      if (
        this.worksession_pos_daily_status
          ?.last_visit_no_question_components_action_results.length > 0
      ) {
        this.worksession_pos_daily_status.last_visit_no_question_components_action_results.map(
          el => {
            action_result.push(el);
            return el;
          }
        );
      }
      return action_result;
    },
    isEmptyBrandSalesStatistics() {
      let ret = false;
      if (this.brand_sales_statistics_data) {
        const foundEmpty = Object.keys(this.brand_sales_statistics_data)
          .filter(key => key !== "displayAmounts")
          .map(el => this.brand_sales_statistics_data[el])
          .filter(el => !el);
        if (
          foundEmpty.length ===
          Object.values(this.brand_sales_statistics_data).length - 1
        ) {
          ret = true;
        }
      }
      return ret;
    }
  },
  methods: {
    moment,
    getImageUri(imgStatic) {
      if (imgStatic && imgStatic?.type === "file") {
        return ApiService.getFileUri(imgStatic);
      }
      return ApiService.getImageUri(imgStatic);
    },
    async startKMModal() {
      if (
        !this.isAdmin &&
        !this.isSubAdmin &&
        !this.isBrand &&
        this.distanceBetweenGPSandPOS !== null &&
        this.distanceBetweenGPSandPOS > 500 &&
        !this.current_km_data
      ) {
        // logError(this.$t("session.start_KM_Modal"));
      }
      if (this.isBrand) {
        if (!this.current_km_data) {
          await this.$refs.dialogStartKM.saveForEmptyKM();
        }
        await this.goToNextStep();
      } else {
        if (this.userLiquidationType !== "TOTAL_KM" && !this.current_km_data) {
          this.showStartKMDialog = true;
        } else {
          await this.goToNextStep();
        }
      }
    },
    async savingStartKM(response) {
      this.goToNextStep();
    },
    showAddClientsDialog() {
      this.$refs.confirmDialogPosPageAddClient.showModal();
    },
    async confirmSelectionCriteria(addCredential) {
      if (!addCredential) return;
      let fetch_url = "worksession/addcurrentvisit";
      const { routeId, posId } = this.posSession;
      let formdata = {
        addCredential,
        routeId,
        posId
      };
      let response = await ApiService.post(fetch_url, formdata);
      if (response.success) {
        logInfo(this.$t("Success"));
      } else {
        logError("We can't add new visit !");
      }
    },
    async saveMessage(messageCategory, formData) {
      const { posId, worksessionId } = this.posSession;
      let curDate = new Date().toISOString().slice(0, 10);
      let addFormData = {
        ...formData,
        mDate: curDate,
        posId,
        brandId: this.selectedBrandId
      };
      console.log("SAVEMESSAGE - ", messageCategory, addFormData);
      this.$refs.dialogAddMessage.setIsLoadingSpinner(true);
      try {
        let addStatus = await ApiService.post(`worksession/addmessage`, {
          addFormData,
          messageCategory
        });
        if (addStatus && addStatus?.success) {
          logInfo(this.$t("Success"));
          this.$refs.dialogAddMessage.hideModal();
          await this.getSessionPosPageData();
        } else {
          logInfo(this.$t("Failed"));
        }
      } catch (error) {}
      this.$refs.dialogAddMessage.setIsLoadingSpinner(false);
    },
    async onClickMessageItem(messageCategory, messageItem) {
      console.log("messageCategory - ", messageCategory, messageItem);
      const { posId, worksessionId } = this.posSession;
      this.isLoadingSpinner = true;
      await this.$refs.dialogMessageList.showModal(
        messageCategory,
        posId,
        this.selectedBrandId
      );
      this.isLoadingSpinner = false;
    },
    async reloadMessages() {
      await this.getSessionPosPageData();
    },
    onClickAddMessage(messageCategory) {
      console.log("messageCategory - ", messageCategory);
      this.$refs.dialogAddMessage.showModal(messageCategory);
    },
    getThumbnailUri(image) {
      return ApiService.getThumbnailUri(image);
    },
    isSurveyResponded(survey) {
      // return this.respondedSurveys.some((el) => el.id === survey.id);
      return false;
    },
    onClickSurveyForBrand() {
      this.$router.push({
        name: "router_worksessionpos_survey",
        params: {
          id: this.sessionId,
          worksessionPosId: this.worksessionPosId,
          brandId: this.selectedBrandId
        }
      });
    },
    checkGoSales() {
      const { posId } = this.posSession;
      let isGoSalesPage = false;
      if (
        posId &&
        this.selectedBrandItem &&
        this.selectedBrandItem.brand_perfectstore_objectives.length > 0
      ) {
        let foundPSObjective = this.selectedBrandItem.brand_perfectstore_objectives.find(
          el => el.posId === posId
        );
        if (
          foundPSObjective &&
          (foundPSObjective.bpoSales === "SUGGESTED_ORDER" ||
            foundPSObjective.bpoSales === "FIRM_ORDER")
        ) {
          isGoSalesPage = true;
        }
      }
      return isGoSalesPage;
    },
    checkHasSurveys() {
      let isHasSurveys = false;
      if (
        this.selectedWorksessionPosBrandItem &&
        this.selectedWorksessionPosBrandItem?.surveys &&
        this.selectedWorksessionPosBrandItem?.surveys.length > 0
      ) {
        isHasSurveys = true;
      }
      return isHasSurveys;
    },
    onClickBackButton() {
      if (this.fromPageCategory === "route") {
        console.log("goto route page");
        this.goEndVisitPage();
      } else {
        let isGoSalesPage = this.checkGoSales();
        let isHasSurveys = this.checkHasSurveys();
        if (isGoSalesPage) {
          this.goSalesPage();
        } else if (isHasSurveys) {
          this.goSurveysPage();
        } else {
          this.goEndVisitPage();
        }
      }
    },
    async logTimestampForWorksessionPosBrand(worksessionPosBrandId) {
      await ApiService.post(`worksession/logtimestamps`, {
        stampCase: "WORKSESSION_POS_BRAND",
        isStart: true,
        dataId: worksessionPosBrandId,
        currentPosition: this.currentPosition
      });
    },
    async goNextStep() {
      let isGoSalesPage = this.checkGoSales();
      let isHasSurveys = this.checkHasSurveys();
      const { visitType, changedVisitType } = this.posSession;
      if (changedVisitType && changedVisitType === "AGENDA") {
        isHasSurveys = false;
      }
      try {
        this.isLoadingSpinner = true;
        await this.logTimestampForWorksessionPosBrand(
          this.worksessionPosBrandId
        );
        if (isHasSurveys) {
          this.goSurveysPage();
        } else if (isGoSalesPage) {
          this.goSalesPage();
        } else {
          this.handleEndVisit(true);
        }
        this.isLoadingSpinner = false;
      } catch (error) {
        this.isLoadingSpinner = false;
        logError("Network error !");
      }
    },
    async changeVisitType(newVisitType) {
      const { visitType, changedVisitType } = this.posSession;
      this.isProcessingGoNext = true;
      this.isLoadingSpinner = true;
      let response = null;
      try {
        response = await ApiService.post(`worksession/changevisittype`, {
          worksessionPosId: this.posSession.id,
          worksessionPosBrandId: this.worksessionPosBrandId,
          visitType,
          changedVisitType: newVisitType,
          isVisit: 1
        });
        this.isProcessingGoNext = false;
      } catch (error) {
        this.isProcessingGoNext = false;
      }
      this.isLoadingSpinner = false;
      return response;
    },
    async onSaveData(item) {
      let body = {
        ...item,
        worksessionPosId: this.posSession.id,
        worksessionPosBrandId: this.worksessionPosBrandId
      };
      try {
        let response = await ApiService.post(
          `worksession/saveDataIfDistanceExceeds`,
          body
        );
        return response;
      } catch (error) {
        logError(error);
      }
    },
    async startWithPresentByUploading() {
      let img = this.$refs["pictureInput"].file;
      if (this.$refs.form_distance_check.validate()) {
        if (!img) {
          logError("Cargar imagen.");
          return;
        }
        this.isLoading["uploading"] = true;
        this.isLoadingSpinner = true;
        try {
          let body = {};
          if (img) {
            let { data } = await ApiService.uploadImage(img);
            logInfo("imagen subida");
            body.photoId = data.id;
          }
          body.isVisit = 1;
          const resp = await this.onSaveData(body);
          if (resp.success) {
            this.goNextStep();
          } else {
            logError("Network Error");
          }
          this.showDistanceCheckDialog = false;
        } catch (error) {}
        this.isLoading["uploading"] = false;
        this.isLoadingSpinner = false;
      }
    },
    async changeVisitTypeFromPresentToPhone() {
      this.isLoading["changingVisitType"] = true;
      logInfo(this.$t("session.confirmation_change_visittype_phone"));
      let changeStatus = await this.changeVisitType("PHONE");
      if (changeStatus && changeStatus?.success) {
        this.posSession.changedVisitType = changeStatus.newVisitType;
      } else {
        logError(
          "El tipo de visita no cambiará debido a un error de red. Inténtelo de nuevo después de un tiempo."
        );
      }
      this.isLoading["changingVisitType"] = false;
      this.showDistanceCheckDialog = false;
      this.goNextStep();
    },
    async goToNextStep() {
      const { visitType, changedVisitType } = this.posSession;
      console.log(
        "calc status - ",
        this.pos_change_gps,
        this.isVisitCurrentPos
      );
      if (
        !this.isAdmin &&
        !this.isSubAdmin &&
        !this.isBrand &&
        visitType === "PRESENT" &&
        !changedVisitType &&
        this.distanceBetweenGPSandPOS !== null &&
        !isNaN(this.distanceBetweenGPSandPOS) &&
        this.distanceBetweenGPSandPOS > 300 &&
        !this.isVisitCurrentPos &&
        (!this.pos_change_gps ||
          (this.pos_change_gps &&
            this.pos_change_gps?.approvalStatus === "INCIDENCE"))
      ) {
        this.showDistanceCheckDialog = true;
        return;
      }
      this.goNextStep();
    },
    async setGpvPosCheckPresent() {
      if (this.isGpvPosCheckPresent) {
        if (this.intervalId) clearInterval(this.intervalId);
        return;
      }
      let accuracy = this.currentPosition?.accuracy;
      if (!accuracy || accuracy > 50) return;
      if (
        this.distanceBetweenGPSandPOS !== null &&
        !isNaN(this.distanceBetweenGPSandPOS) &&
        this.distanceBetweenGPSandPOS <= 300
      ) {
        this.onSaveData({ isGpvPosCheckPresent: 1 });
        if (this.intervalId) clearInterval(this.intervalId);
      } else {
        this.onSaveData({ isGpvPosCheckPresent: 0 });
      }
    },
    async onClickSurveyOrSalesNext() {
      if (this.current_km_data) {
        if (
          !this.isAdmin &&
          !this.isSubAdmin &&
          !this.isBrand &&
          this.distanceBetweenGPSandPOS !== null &&
          this.distanceBetweenGPSandPOS > 500
        ) {
          // logError(this.$t("session.start_KM_Modal"));
        }
        await this.goToNextStep();
      } else {
        await this.startKMModal();
      }
    },
    goSurveysPage() {
      console.log("----- go survey page ! -----");
      this.$router.push({
        name: "router_worksessionpos_surveyv2",
        params: {
          id: this.sessionId,
          worksessionPosId: this.worksessionPosId,
          brandId: this.selectedBrandId
        }
      });
    },
    goSalesPage() {
      console.log("----- go sales page ! -----");
      this.$router.push({
        name: "router_worksessionsales",
        params: {
          id: this.sessionId,
          worksessionPosId: this.worksessionPosId,
          worksessionPosBrandId: this.worksessionPosBrandId
        }
      });
    },
    goEndVisitPage() {
      console.log("----- go endvisit page ! -----");
      this.$router.push({
        name: "router_worksessionroute",
        params: {
          id: this.sessionId
        }
      });
    },
    showDialogOnePage() {
      if (this.isShowOnepageModal > 0) {
        this.$refs.dialogOnePage.showModal();
      } else {
        logError("No hay datos para mostrar.");
      }
    },
    showDialogPromos() {
      if (this.isShowPromosModal > 0) {
        this.$refs.dialogPromos.showModal();
      } else {
        logError("No hay datos para mostrar.");
      }
    },
    async handleEndVisit(isNoSalesAndNoSurveys) {
      this.isLoadingSpinner = true;
      try {
        let response = await ApiService.post(
          `worksession/endvisitworksessionposbrand/${this.worksessionPosBrandId}`,
          {
            isNoSalesAndNoSurveys,
            currentPosition: this.currentPosition
            // gmapAddress: this.currentGmapAddress
          }
        );
        this.isProcessingEndVisit = false;
        this.goEndVisitPage();
      } catch (error) {
        this.isProcessingEndVisit = false;
      }
      this.isLoadingSpinner = false;
    },
    async onClickEndVisit() {
      if (this.isEndedSession) {
        this.goEndVisitPage();
      } else {
        let currentVisitDate = new Date().toISOString().slice(0, 10);
        if (this.posSession?.worksession) {
          currentVisitDate = this.posSession?.worksession.date;
        }
        if (
          !this.brand_incidence_messages?.brand_messaging ||
          (this.brand_incidence_messages?.brand_messaging &&
            this.brand_incidence_messages?.brand_messaging.length === 0)
        ) {
          logError("No hay Apuntes Ult. Visita");
          return;
        }
        if (this.brand_incidence_messages?.brand_messaging.length > 0) {
          let dates = this.brand_incidence_messages?.brand_messaging.map(
            el => el.mDate
          );
          let foundDate = dates.filter(el => el === currentVisitDate);
          if (foundDate.length === 0 && (this.isGPV || this.isSPV)) {
            logError("No hay Apuntes Ult. Visita");
            return;
          }
        }
        this.isLoadingSpinner = true;
        this.isProcessingEndVisit = true;
        this.handleEndVisit(this.isNoSalesAndNoSurveys);
      }
    },
    onClickPOSData() {
      this.$router.push({
        name: "router_editposdata_edit",
        params: {
          id: this.sessionId,
          worksessionPosId: this.worksessionPosId,
          fromPage: this.fromPageCategory
        }
      });
    },
    closeDistanceCheckDialog() {
      this.showDistanceCheckDialog = false;
      this.$nextTick(() => {
        this.selected_image = null;
      });
    },
    closeDialogCheckDistance() {
      this.dialogCheckDistance = false;
      this.$nextTick(() => {});
    },
    confirmLocationDistance(isConfirmed) {
      this.closeDialogCheckDistance();
      if (isConfirmed) {
        console.log("isConfirmed - ", isConfirmed);
      }
    },
    onChangePicture() {
      this.selected_image = this.$refs["pictureInput"].file;
    },
    checkDistance() {
      if (!this.posSessionGeoLocation) {
        logError(
          "Go to POS Data/Change GPS to update POS Data before continuing."
        );
        return;
      }
      if (this.currentPosition) {
        let posLocationPt = {
          lat: this.posSessionGeoLocation.latitude,
          lng: this.posSessionGeoLocation.longitude
        };
        let currentLocationPt = {
          lat: this.currentPosition.latitude,
          lng: this.currentPosition.longitude
        };
        var haversine_m = haversine(posLocationPt, currentLocationPt); //Results in meters (default)
        if (haversine_m > 200) {
          this.dialogCheckDistance = true;
        }
      } else {
        logError(
          "You should allow the browser's permission for detecting location."
        );
        this.dialogCheckDistance = true;
      }
    },
    filterAvailablePromos(promosList) {
      let curDate = new Date();
      const today = curDate.toISOString().slice(0, 10);
      var endDate = new Date(new Date().setDate(curDate.getDate() + 30));
      const endday = endDate.toISOString().slice(0, 10);
      let filteredPromos = promosList.filter(el => {
        if (el.statusKey === "ACTIVE" || el.statusKey === "PREPARE") {
          return true;
        }
        if (el.startDate <= endday && el.endDate >= today) return true;
        return false;
      });
      if (filteredPromos.length > 0) {
        filteredPromos = filteredPromos
          .map(el => {
            let pushItem = { ...el };
            return pushItem;
          })
          .filter(el => el);
      }
      return filteredPromos;
    },
    async onChangeSelectedBrand() {
      if (this.selectedBrandId) {
        let foundBrandItem = this.worksessionPosBrands.find(
          el => el.id == this.selectedBrandId
        );
        if (foundBrandItem) {
          this.worksessionPosBrandId = foundBrandItem.worksession_pos_brands.id;
          this.selectedWorksessionPosBrandItem = foundBrandItem;
          this.currentPromosForSelectedBrand = this.filterAvailablePromos(
            foundBrandItem?.brand_promos
          );
        } else {
          this.currentPromosForSelectedBrand = [];
        }
      }
    },
    async onChangeBrandId(currentSelectedBrandId) {
      this.selectedBrandId = currentSelectedBrandId;
      this.onChangeSelectedBrand();
      await this.getSessionPosPageData();
    },
    async onClickSelectedBrand(currentSelectedBrandId) {
      this.$router
        .push({
          path: `/session/${this.sessionId}/pospage/${this.worksessionPosId}/${this.fromPageCategory}/${currentSelectedBrandId}`
        })
        .catch(() => {});
    },
    async markedAsReadOverallMessages() {
      const { posId, worksessionId } = this.posSession;
      let messageOverallIds = this.overall_messages.map(el => el.id);
      this.isShowOverallMessages = false;
      await ApiService.put(
        `worksession/markedAsReadOverallMessages/${posId}/${this.selectedBrandId}`,
        {
          messageOverallIds
        }
      );
    },
    async onChangeSalesFamily() {
      await this.getSessionPosPageData();
    },
    getKPIQuestionItemWithSortedKey(kpi_questions, questionId) {
      let found = kpi_questions.find(el => el.id == questionId);
      return found;
    },
    async getSessionPosPageData() {
      const { frompage } = this.$route.params;
      this.isLoadingSessionpospagedata = true;
      const { routeId, posId, worksessionId } = this.posSession;
      try {
        const {
          brand_sales_statistics_data,
          salesFamilyList,
          worksession_pos_daily_status,
          overall_messages,
          brand_incidence_messages,
          mStatus,
          promo_locals,
          selectableBrands,
          selectedBrandItem
        } = await ApiService.post(
          `worksession/getsessionpospagedata/${worksessionId}/${this.worksessionPosId}/${posId}/${this.selectedBrandId}`,
          {
            posSessionChainCriteria: this.posSessionChainCriteria,
            routeId,
            posId,
            frompage,
            selectedSalesFamilies: this.selectedSalesFamilies
          }
        );
        
        this.brand_sales_statistics_data = brand_sales_statistics_data;
        this.salesFamilyList = salesFamilyList;
        this.worksession_pos_daily_status = worksession_pos_daily_status;
        this.brand_incidence_messages = brand_incidence_messages;
        this.mStatus = mStatus;
        this.promo_locals = promo_locals;
        this.overall_messages = overall_messages;
        this.selectableBrands = selectableBrands;
        this.selectedBrandItem = selectedBrandItem;
        if (this.overall_messages.length > 0) {
          this.isShowOverallMessages = true;
        } else {
          this.isShowOverallMessages = false;
        }
      } catch (error) {
        logError(error);
      } finally {
        await this.getKPISessionPosPage();
        this.isLoadingSessionpospagedata = false;
      }
    },
    async getKPISessionPosPage() {
      const { frompage } = this.$route.params;
      const { routeId, posId, worksessionId } = this.posSession;
      try {
        const { kpi_questions, sortedKeysQuestions } = await ApiService.post(
          `worksession/getKPISessionPosPage/${worksessionId}/${this.worksessionPosId}/${posId}/${this.selectedBrandId}`,
          {
            posSessionChainCriteria: this.posSessionChainCriteria,
            routeId,
            posId,
            frompage
          }
        );
        this.kpi_questions = kpi_questions;
        this.sortedKeysQuestions = sortedKeysQuestions;
      } catch (error) {
        console.log("error - ", error);
      }
    },
    getVisitTableRowStyle(rowData) {
      let rowClassName = "";
      if (rowData.comparedScore > 0) {
        rowClassName = "green-tr";
      } else if (rowData.comparedScore < 0) {
        rowClassName = "brown-tr";
      }
      return rowClassName;
    },
    routePosItemIdLabel(posSession) {
      let ret = null;
      let labelItems = [];
      if (posSession?.po) {
        let idPosBrandList = [];
        idPosBrandList = this.getIdPosBrandListFromBrands(posSession.brands);
        idPosBrandList = idPosBrandList.map(el => el.idPosBrand);
        if (idPosBrandList.length > 0) {
          labelItems.push(idPosBrandList.join(", "));
        }
      }
      if (labelItems.length > 0) ret = labelItems.join(", ");
      return ret;
    },
    async initialize() {
      const { id, worksessionPosId } = this.$route.params;
      try {
        let fetch_url = `worksession/${id}`;
        const {
          data,
          meta,
          visitTypes,
          user_route,
          isOptimizedPos,
          startedWorksessionPosCount,
          vehicleTypeList,
          current_km_data
        } = await ApiService.get(fetch_url);
        if (user_route) {
          if (Array.isArray(user_route) && user_route.length > 0) {
            this.mainRoute = user_route[0];
          } else {
            this.mainRoute = null;
          }
        }
        this.startedWorksessionPosCount = startedWorksessionPosCount;
        this.vehicleTypeList = vehicleTypeList;
        this.current_km_data = current_km_data;
        this.visitTypes = visitTypes;
        this.session = data;
      } catch (error) {
        logError("Network error !");
        console.log("error - ", error);
      }
    },
    async init() {
      await this.initialize();
      const { id, worksessionPosId } = this.$route.params;
      this.sessionId = id;
      this.worksessionPosId = worksessionPosId;
      const {
        data,
        reasonTypes,
        worksessionPosBrands,
        promosTypes,
        placeTypes,
        visitTypes
      } = await ApiService.get(`worksession/pos/${this.worksessionPosId}`);

      this.isGpvPosCheckPresent = data?.isGpvPosCheckPresent;
      this.isVisitCurrentPos = data?.isVisit;
      this.promosTypeList = promosTypes;
      this.placeTypeList = placeTypes;
      this.visitTypes = visitTypes;
      this.posSession = data;
      this.worksessionPosBrands = worksessionPosBrands;
      this.pos_change_gps = this.getPosChangeGeolocationManualData(
        this.posSession,
        this.user.id
      );
      if (this.posSession && this.posSession.po) {
        const { latitude, longitude } = this.posSession.po;
        if (latitude && longitude) {
          this.posSessionGeoLocation = {
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude)
          };
        } else {
          this.posSessionGeoLocation = null;
        }
      }
      if (
        this.posSession &&
        this.posSession.visitType === "PRESENT" &&
        !this.posSession.changedVisitType
      ) {
        this.$store.dispatch("myroute/getCurrentPosition");
      }
      if (this.posSession && this.posSession?.po) {
        const { chainId, subChainId, sub2ChainId } = this.posSession.po;
        this.posSessionChainCriteria = {
          chainId,
          subChainId,
          sub2ChainId
        };
      }

      const { brandId } = this.$route.params;
      if (brandId) {
        this.selectedBrandId = parseInt(brandId);
      }
      if (this.worksessionPosBrands && this.worksessionPosBrands.length > 0) {
        if (!this.selectedBrandId) {
          this.selectedBrandId = this.worksessionPosBrands[0].id;
        }
        this.worksessionPosBrandId = null;
        this.onChangeSelectedBrand();
        await this.getSessionPosPageData();
      }
      this.intervalId = setInterval(this.setGpvPosCheckPresent, 60000);
    }
  },

  async mounted() {
    await this.init();
  }
};
</script>

<style>
.brand_sales_module {
  position: relative;
}
.brand_sales_module .title_caption {
  font-size: 1rem;
  margin: 0 0.5rem;
  font-weight: 500;
}
.brand_sales_module .sales_value {
  background: none !important;
  font-size: 1.5rem;
  font-weight: 800;
  margin: 0 0.5rem;
}
.brand_sales_module .sales_value.green {
  color: #4caf50 !important;
}
.brand_sales_module .sales_value.red {
  color: #f44336 !important;
}
p.last_updated_caption {
  margin: 0.4rem 0;
}
.status_wrapper .bg-dark {
  background-color: #9f9f9f !important;
  color: #000;
}

.scroll_contents {
  max-height: 500px;
  overflow-y: scroll;
}
table.ventas-table {
  text-align: center;
}
th.ventas-table-th-title {
  width: 80px;
}
th.ventas-table-th {
  width: 60px;
}
th.ventas-table-th.green-th {
  background: #e2efda;
}
th.no-border {
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}
th.table-title {
  font-size: 1rem;
  text-align: left;
  padding: 5px 10px;
  text-decoration: underline;
  font-weight: 800;
}
.ventas-table .black-tr th {
  background: #000;
  color: #fff;
}
.ventas-table .green-tr td {
  background: #e2efda;
}
.ventas-table .grey-td {
  background: #dbdbdb;
}
.ventas-table .grey-tr td {
  background: #dbdbdb;
}
.ventas-table .brown-td {
  background: #f8cbad;
}
.ventas-table .brown-tr td {
  background: #f96060;
}
.globalsnackbarparagraph img {
  max-width: 100% !important;
}
.btn_back_surveyanswerpage {
  color: #1976d2;
}
.btn_back_surveyanswerpage:hover {
  background: #fff;
  color: #1976d2;
}
</style>
